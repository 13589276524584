function getPrivacyPageForLang  (lang)  {
    if (lang === 'de') {
        return '/meta/datenschutz';
    } else {
        return '/en/meta/datenschutz';
    }
}

window.CookieConsent.init({
    // More link URL on bar
    modalMainTextMoreLink: getPrivacyPageForLang(window.localStorage.getItem('lang')), //unused replaced by
    privacyLink: getPrivacyPageForLang(window.localStorage.getItem('lang')),
    // How lond to wait until bar comes up
    barTimeout: 1000,
    // Look and feel
    theme: {
        barColor: 'rgba(64, 73, 86, 1)',
        barTextColor: '#FFF',
        barMainButtonColor: '#f16b52',
        barMainButtonTextColor: '#FFF',
        modalMainButtonColor: '#f16b52',
        modalMainButtonTextColor: '#FFF',
    },
    language: {
        // Current language
        current: window.localStorage.getItem('lang') || 'de',
        locale: {
            de: {
                barMainText: 'Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten zu können und die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben.',
                barLinkSetting: 'Cookie Einstellungen',
                barBtnAcceptAll: 'Alle akzeptieren',
                modalMainTitle: 'Cookie Einstellungen',
                privacyTitle: ' Datenschutzerklärung',
                modalMainText: 'Wir verwenden bei der Nutzung unserer Webseiten so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die auf Ihrem Endgerät gespeichert werden. ',
                modalBtnSave: 'Einstellungen speichern',
                modalBtnAcceptAll: 'Alle Cookies akzeptieren',
                modalAffectedSolutions: 'Betroffene Funktionalität:',
                learnMore: 'Mehr erfahren',
                on: 'Aktiv',
                off: 'Inaktiv',
            },
            en: {
                barMainText: 'We use cookies to personalize the contents of the webpage, to enable social media integrations, and to analyze website traffic. We may refer your data to our partners for advertising and analytics. These partners may use this data with other personal data you have provided them with or have allowed them to collect.  ',
                barLinkSetting: 'Cookie Settings',
                barBtnAcceptAll: 'Accept All',
                modalMainTitle: 'Cookie Settings',
                privacyTitle: ' Privacy Policy',
                modalMainText: 'We use cookies on our websites. Cookies are small text files that are stored on your device.',
                modalBtnSave: 'Save Settings',
                modalBtnAcceptAll: 'Accept All',
                modalAffectedSolutions: 'Affected services:',
                learnMore: 'Learn more',
                on: 'On',
                off: 'Off',
            },
        }
    },
    // List all the categories you want to display
    categories: {
        // Unique name
        // This probably will be the default category
        functional: {
            // The cookies here are necessary and category cant be turned off.
            // Wanted config value  will be ignored.
            needed: true,
            // The cookies in this category will be let trough.
            // This probably should be false if not necessary category
            wanted: true,
            // If the checkbox is on or off at first run.
            checked: true,
            // Language settings for categories
            language: {
                locale: {
                    en: {
                        name: 'Functional Cookies',
                        description: 'These cookies are used to store settings on the website and data about the restrictions on non-essential cookies.',
                    },
                    de: {
                        name: 'Funktionale Cookies',
                        description: 'Diese Cookies werden verwendet, um Einstellungen auf der Website und solche, die Sie in Bezug auf die Verwendung von technisch nicht notwendigen Cookies gewählt haben, zu speichern.',
                    }
                }
            }
        },
        vimeo: {
            // The cookies here are necessary and category cant be turned off.
            // Wanted config value  will be ignored.
            needed: false,
            // The cookies in this category will be let trough.
            // This probably should be false if not necessary category
            wanted: false,
            // If the checkbox is on or off at first run.
            checked: false,
            // Language settings for categories
            language: {
                locale: {
                    en: {
                        name: 'Vimeo',
                        description: '',
                    },
                    de: {
                        name: 'Vimeo',
                        description: '',
                    }
                }
            }
        },
        linkedin: {
            // The cookies here are necessary and category cant be turned off.
            // Wanted config value  will be ignored.
            needed: false,
            // The cookies in this category will be let trough.
            // This probably should be false if not necessary category
            wanted: false,
            // If the checkbox is on or off at first run.
            checked: false,
            // Language settings for categories
            language: {
                locale: {
                    en: {
                        name: 'LinkedIn Insight Tag',
                        description: '',
                    },
                    de: {
                        name: 'LinkedIn Insight Tag',
                        description: '',
                    }
                }
            }
        }

    },
    // List actual services here
    services: {
        functional: {
            // Existing category Unique name
            // This example shows how to block Google Analytics
            category: 'functional',
            // Type of blocking to apply here.
            // This depends on the type of script we are trying to block
            // Can be: dynamic-script, script-tag, wrapped, localcookie
            type: 'localcookie',
            // List of known cookie names or Regular expressions matching
            // cookie names placed by this service.
            // These willbe removed from current domain and .domain.
            cookies: [
                // {
                //     // Known cookie name.
                //     name: 'slidesSubscribed',
                //     // Expected cookie domain.
                //     domain: `.${window.location.hostname}`
                // },
                // {
                //     Regex matching cookie name.
                // name: /^_ga/,
                // domain: `.${window.location.hostname}`
                // }
            ],
            language: {
                locale: {
                    en: {
                        name: 'Page Settings'
                    },
                    de: {
                        name: 'Seiteneinstellungen'
                    }
                }
            }
        },
        vimeo : {
            category: 'vimeo',
            type: 'script-tag',
            search: 'vimeo', // todo wrap all videos
            cookies: [
                {
                    name: "vimeo",
                    domain: "player.vimeo.com"
                }
            ],
            language: {
                locale: {
                    en: {
                        name: 'Vimeo'
                    },
                    de: {
                        name: 'Vimeo'
                    }
                }
            }
        },
        linkedin : {
            category: 'linkedin',
            type: 'script-tag',
            search: 'linkedin', // todo
            cookies: [
                { // todo
                    name: "vimeo",
                    domain: "player.vimeo.com"
                }
            ],
            language: {
                locale: {
                    en: {
                        name: 'LinkedIn Insight Tag'
                    },
                    de: {
                        name: 'LinkedIn Insight Tag'
                    }
                }
            }
        }
    }
});



